/* Variables
   ----------------------------- */

$break-mobile: max-width 600px;
$break-tablet: max-width 768px;

$sm: 600px;
$md: 768px;
$lg: 992px;

/* Imports
   ----------------------------- */

@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,500,700);
@import "./node_modules/breakpoint-sass/stylesheets/breakpoint";
@import "partials/colors";
@import "partials/helpers";
@import "partials/normalize";
@import "partials/hover";
@import "partials/utilities";

/* HTML
   ----------------------------- */

html {
  box-sizing: border-box;
  font-size: 62.5%; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  @include font-size(16);
  font-family: 'Ubuntu', sans-serif;
  min-height: 100%;
  position: relative;
  background-color: rgb(255, 60, 31); }

.lt-ie9 body {
  margin-top: 5em; }

ul, section, body {
  margin: 0;
  padding: 0; }

a {
  text-decoration: none;
  // +link-colors($white, $primary)
  &:hover, &:focus {
    text-decoration: none; } }

a, input {
  transition: all .3s ease; }

a, input, button, textarea, select {
  &:active,
  &:focus {
    box-shadow: none;
    outline: none; } }

p {
  line-height: 1.5;
  font-weight: 300; }

hr {
  margin: 2em 0;
  height: 1px;
  border: none;
  background: #404040; }

pre {
  background-color: #eaecef;
  border-radius: 3px;
  font-size: 85%;
  line-height: 1.45;
  overflow: auto;
  padding: 16px;
  margin: 16px 32px; }


/* Styles
   ----------------------------- */

.ion-1 {
  font-size: 1em; }

.ion-2 {
  font-size: 2em;
  line-height: 1.4; }

.side {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 30%;
  background: $black;
  overflow-y: auto;
  @include breakpoint($break-mobile) {
    width: 100%; } }

.side, .timeline li {
  &:before {
    position: absolute;
    left: 24px;
    top: 0;
    content: ' ';
    display: block;
    width: 2px;
    height: 100%;
    margin-left: -3px;
    background: #444;
    z-index: 5; } }

.main {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  // background: $black url('../img/3-dark2.jpg') top center no-repeat
  //+background-size(cover)
  background: white;
  z-index: 20;
  transition: transform .3s ease;
  box-shadow: 0 0 10px $black-darker;
  overflow: auto; }

.work {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 70%;
  z-index: 21;
  overflow-x: hidden;
  display: none;
  @include breakpoint($break-mobile) {
    overflow-x: hidden;
    width: 100%; }
  a {
    color: $black-light; } }

.work-info {
  h3 {
    text-align: center;
    font-size: 2em;
    margin: 1em; }
  h4 {
    margin: 0 32px 8px;
    font-size: 1.5em; }
  iframe:not(.cp_embed_iframe) {
    height: 447px; }
  iframe {
    width: 100%;
    margin: 1em auto;
    @include breakpoint($break-mobile) {
      height: 56vw; } }
  hr {
    background: #EAEAEA; }
  img {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 32px; }
  p {
    margin: 0 32px 32px;
    line-height: 1.5;
    em {
      display: block;
      padding: 1em 2em;
      @include breakpoint($break-mobile) {
        padding: 0; } } }
  a {
    color: $primary;
    &:hover {
      text-decoration: underline; }
    @extend .fancy-hover; }
  h5 {
    margin: 0 32px 8px; } }

.main-image-container {
  position: relative;
  height: 300px;
  pointer-events: none;
  overflow: hidden;
  @include breakpoint($md) {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden; } }

.main-image-me {
  position: absolute;
  top: 0;
  left: 35%;
  content: '';
  width: 100%;
  height: 100%;
  background: url('../img/banner-me.png');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
  pointer-events: none; }

.main-image {
  width: 100%;
  height: 300px;
  position: absolute;
  background-image: url('../img/banner-bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  pointer-events: none;
  overflow: hidden;
  @include breakpoint($md) {
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 70%;
    z-index: -1; } }

.intro {
  padding: 2em;
  color: $black;
  background: white;
  width: 100%;
  position: relative;
  overflow: auto;
  @include breakpoint($sm) {
    max-width: 500px;
    padding: 4em;
    padding-bottom: 1em; }
  @include breakpoint($md) {
    height: 100vh; } }

.timeline {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 1em 0;
  list-style-type: none;
  li {
    padding: 1em 0 1em 2em;
    cursor: pointer;
    @extend .animate;
    &:hover {
      background: $black-light; }
    &:after {
      content: "";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden; }
    &.active {
      background: $black-dark; } } }

.direction-r {
  position: relative;
  width: 100%;
  float: right; }

.flag {
  position: relative;
  display: inline-block;
  padding: 0 1em;
  border-radius: 5px;
  font-weight: 600;
  text-align: left;
  color: $white;
  margin: 0;
  @include font-size(16);
  &:before {
    position: absolute;
    top: 60%;
    left: -16px;
    content: ' ';
    display: block;
    width: 12px;
    height: 12px;
    margin-top: -10px;
    background: $black-dark;
    border-radius: 10px;
    border: 2px solid $primary;
    box-shadow: 0 0 0 3px #444;
    z-index: 10; } }

.desc {
  margin: 1em 1em 0;
  color: $white;
  @include font-size(12);
  font-style: italic;
  line-height: 1.5em;
  color: #777; }

.lt-ie9 .work-container {
  overflow-y: scroll; }

.work-piece {
  position: absolute;
  width: 90%;
  height: 90%;
  left: 100%;
  right: 0;
  top: 5%;
  max-width: 900px;
  background: $white-lighter;
  padding: 0;
  margin: 0 auto;
  list-style: none;
  z-index: 30;
  overflow-y: auto;
  display: none;
  @include breakpoint($break-mobile) {
    width: 100%;
    height: 100%;
    top: 0; }
  &.active {
    display: block; }
  .button {
    border: 1px solid $white-dark;
    &:hover {
      background: $primary;
      color: $white;
      border: 1px solid $primary-dark; } } }

.buttons {
  padding: 1em; }

.prev, .next, .backtotop {
  padding: 0;
  z-index: 10;
  &.ion-1 {
    font-size: 1.4em;
    line-height: 1.8; } }

.prev {
  left: 1em; }

.next {
  right: 1em; }

.backtotop.button {
  display: inline-block; }

.close, .prev, .next, .backtotop {
  width: 44px;
  height: 44px;
  display: block;
  bottom: 1em;
  text-align: center;
  &:before {
    line-height: 41px; } }

.close {
  position: absolute;
  right: 15px;
  top: 0;
  color: $black-light;
  background: $white-lighter;
  z-index: 30;
  display: none;
  &:hover {
    color: $white;
    background: $primary; } }

.checklink {
  width: auto;
  @include breakpoint($break-mobile) {
    width: 100%;
    margin-bottom: 4em; } }

.load {
  position: absolute;
  top: .8em;
  left: 49%;
  width: 38px;
  height: 38px;
  background: url('../img/loading.svg') center center no-repeat;
  // background: url('../img/loader2.gif') center center no-repeat
  display: none;
  @include breakpoint($break-mobile) {
    left: .8em; } }

.helper {
  position: absolute;
  padding: 7px 7px 1em;
  right: 2em;
  top: 20%;
  z-index: 40;
  background: $black;
  border-radius: 25px;
  opacity: 0;
  transform: translate3d(120%, 0, 0);
  transition: all .2s ease-in-out;
  &.helper-active {
    opacity: 1;
    transform: translate3d(0%, 0, 0); } }

.helper-note {
  display: block;
  padding: 10px 10px 10px 1em;
  background: $white;
  border-radius: 50px;
  @include breakpoint($break-mobile) {
    display: none; }
  span {
    padding: 7px 8px;
    border-radius: 100%;
    margin-left: .5em; } }

.legend {
  display: inline-block;
  margin: .5em 0 0 1em;
  list-style: none;
  color: white;
  line-height: 2;
  li:before {
    content: '';
    display: inline-block;
    margin-right: 10px;
    width: 12px;
    height: 12px;
    background: transparent;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%; } }

.about-me {
  background: none;
  border: 1px solid $black;
  color: $black; }

.about-me-details {
  font-weight: normal;
  a {
    border-bottom: 1px dotted $primary;
    color: $black;
    // &:hover
 } }    //   color: $primary-lighter

#checkout {
  color: $white;
  background: #000;
  @include breakpoint($break-mobile) {
    width: auto; } }

.about-me, #checkout {
  &:hover {
    background: $primary;
    border-color: $primary;
    color: $white;
    opacity: 1; }
  @include breakpoint($break-mobile) {
    display: block; } }

.about {
  z-index: 30;
  display: none;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh !important;
  background: white;
  padding: 0;
  overflow-y: auto;
  @include font-size(40);
  color: $black;
  // text-align: center
  @include breakpoint($break-mobile) {
    @include font-size(30);
 } }    // padding: 20px 20px 0

.about-close {
  color: $white;
  position: fixed;
  right: 2em;
  top: 1em;
  display: block;
  padding: 0 .8em;
  @include font-size(20);
  background: black;
  border-radius: 50%;
  &:hover {
    background: $primary; } }

.social {
  display: flex; }

// .made-with-love
//   +breakpoint($md)
//     position: absolute
//     bottom: 5em

.logo {
  display: inline-block;
  width: 100px;
  @include breakpoint($break-mobile);
  svg {
    fill: $black; }
  path {
    fill: inherit; } }

.about-container {
  @include font-size(16);
  display: block;
  margin: 0;
  padding: 3em;
  width: 100%;
  @include breakpoint($lg) {
    padding: 4em;
    padding-right: 46%; } }

.about-image {
  width: 100%;
  height: 30vh;
  background-image: url('../img/banner-about3.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
  pointer-events: none;
  @include breakpoint($sm) {
    height: 50vh; }
  @include breakpoint($lg) {
    position: fixed;
    top: 0;
    right: 0;
    width: 40%;
    height: 100vh; } }

@keyframes glow-grow {
  0% {
    opacity: 0;
    transform: scale(0); }
  70% {
    opacity: 1; }
  100% {
    transform: scale(1.2);
    opacity: 0; } }

.about-details {
  li {
    padding: 0;
    list-style-type: none;
    text-align: left;
    margin: 1em 0;
    font-weight: normal;
    width: 100%;
    @include breakpoint($sm) {
      width: 50%; }
    &.odd {
      float: left; }
    &.even {
      float: right; }
    span {
      margin-bottom: 5px;
      text-transform: uppercase;
      font-weight: bold;
      display: block;
      color: $white-darker;
      letter-spacing: 1px;
      @include font-size(12); }
    a {
      color: black;
      @extend .fancy-hover; } } }

.at:after {
  content: '@'; }
.dot:after {
  content: '.'; }

.bg {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 22;
  background: rgba(black, .75);
  display: none; }

.graph-bg {
  height: 76px;
  width: 100%;
  background: url(../img/data.gif) 0 0 repeat-x;
  opacity: .25; }

.tippy-tooltip {
  font-size: .9em !important; }

#particles-js {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1; }

/* Color Labels
   ----------------------------- */

.web:before {
  border-color: $blue; }
.design:before {
  border-color: $yellow; }
.print:before {
  border-color: $teal; }
.animation:before {
  border-color: $purple; }
.industrial:before {
  border-color: $primary; }
.none:before {
  border-color: $white; }
