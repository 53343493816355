.fancy-hover {
  position: relative;
  text-decoration: none;
  display: inline-block;
  color: black;
  padding: 0 1px;
  transition: color ease 0.3s;
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 5%;
    left: 0;
    bottom: 0;
    background-color: $primary;
    transition: all ease 0.3s;
  }
  &:hover {
    color: white;
    &::after {
      height: 100%;
    }
  }
}
