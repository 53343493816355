.element-invisible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.element-invisible.element-focusable:active,
.element-invisible.element-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.animate {
  transition: all .2s ease; }

@mixin link-colors($normal, $hover: false, $active: false, $visited: false, $focus: false) {
  color: $normal;
  @if $visited {
    &:visited {
      color: $visited; } }
  @if $focus {
    &:focus {
      color: $focus; } }
  @if $hover {
    &:hover {
      color: $hover; } }
  @if $active {
    &:active {
      color: $active; } } }

@mixin font-size($sizeValue: 16) {
  font-size: ($sizeValue) + px;
  font-size: ($sizeValue / 10) + rem; }

.clearfix {
  &:after {
      content: "";
      display: table;
      clear: both; } }

.tac {
  text-align: center; }

.fr {
  float: right; }

.fl {
  float: left; }

@mixin hide-text() {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden; }

.hide-text {
  display: block;
  @include hide-text(); }

.hidden {
  display: none !important; }

.button {
  display: inline-block;
  padding: 14px 27px 14px 29px;
  white-space: nowrap;
  border-radius: 50px;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  @include font-size(14);
  background: $white;
  border: 1px solid transparent;
  @extend .animate;
  &:hover {
    opacity: .8;
    color: $black; } }

.color-primary {
  color: $primary; }
